import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Dons from "../components/dons"
import CoverBlog from "../components/cover-blog"
import Posts from "../components/posts"

const DonationPage = () => (
  <Layout>
    <Seo title="Dons" />
    <CoverBlog postTitle={"Don"} />
    <section
      id="contact"
      style={{
        paddingTop: "2em",
        paddingBottom: "2em",

        // height: "calc(100vw * 9 /16)",
        // maxHeight: "1000px",
      }}
    >
      <div
        style={{
          display: "flex",
          // flexDirection: "row",
          alignItems: "flex-start",
          // height: "100%",
          margin: `0 auto`,
          maxWidth: 960,
          padding: `1.45rem 1.0875rem`,
          // justifyContent: "center",
          // alignItems: "center",
        }}
      >
        <div style={{ width: "560px" }}>
        <Dons></Dons>
        </div>
        <Posts lastPosts />
      </div>
    </section>
    {/* <h1>Hi from the Dons page</h1>
    <p>Welcome to page Dons</p>
    <Link to="/">Go back to the homepage</Link> */}
  </Layout>
)

export default DonationPage
